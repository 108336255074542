import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
//import PrimaryLayout from '../components/layout/primary/primary';
import SecondaryLayout from "../components/layout/secondary/secondary";
import HomeBanner from '../components/home-banner/home-banner';
//import CategoryBlocks from '../components/category-blocks/primary/primary';
import CategoryBlocks from '../components/category-blocks/secondary/secondary';
import CallusBanner from '../components/call-us-banner/call-us-banner';
import AllProducts from "../components/all-products/all-products";

const indexPageStaticQuery = graphql`
	query {
		prismic {
			allChat2orderhomes {
				edges {
					node {
						banner
						banner_button_text
						banner_subtitle
						banner_title
						category_section_title
						category_section_short_intro						
						category_block {
							category_slug
							category_title
							image
							imageSharp {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_withWebp_tracedSVG
									}
								}
							}
						}						
						call_us_banner
						call_us_button_text
						call_us_title
						call_us_subtitle
						view_more_text
					}
				}
			}
		}
	}
`;

const IndexPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${indexPageStaticQuery}`}
		render={(data) => {
			const node = get(data, 'prismic.allChat2orderhomes.edges[0].node');
			const banner = node.banner.url;
			const bannerTitle = node.banner_title;
			const bannerSubTitle = node.banner_subtitle;
			const bannerButtonText = node.banner_button_text;
			//const categoryBlock = node.category_block;
			const categorySectionTitle = get(
				data,
				'prismic.allChat2orderhomes.edges[0].node.category_section_title'
			);
			const categorySectionIntro = get(
				data,
				'prismic.allChat2orderhomes.edges[0].node.category_section_short_intro'
			);
			const categoryItems = get(
				data,
				'prismic.allChat2orderhomes.edges[0].node.category_block'
			);			
			const callUsBanner = node.call_us_banner;
			const callUsTitle = node.call_us_title;
			const callUsSubTitle = node.call_us_subtitle;
			const callUsButtonText = node.call_us_button_text;
			const howItWorksTitle = node.how_it_works_title;
			const howItWorksSubtitle = node.how_it_works_subtitle;
			const howItWorksGroup = node.how_it_works_group;

			return (
				<SecondaryLayout>
					<SEO title="Home" />
					{/* End of home seo */}
					<HomeBanner
						banner={banner}
						bannerTitle={bannerTitle}
						bannerSubTitle={bannerSubTitle}
						bannerButtonText={bannerButtonText}
					/>
					<CategoryBlocks
						title={categorySectionTitle}
						shortIntro={categorySectionIntro}
						categoryItems={categoryItems}
					/>					
					<AllProducts withLink={true}/>					
					<CallusBanner
						callUsBanner={callUsBanner}
						callUsTitle={callUsTitle}
						callUsSubTitle={callUsSubTitle}
						callUsButtonText={callUsButtonText}
					/>
				</SecondaryLayout>
			);
		}}
	/>
);

export default IndexPage;
